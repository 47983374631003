import { Inject, Injectable, OnDestroy, OnInit, PLATFORM_ID, TemplateRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ToastService } from '../../shared/services/toast.service';
import { AuthService } from '../auth/auth.service';
import { SiteContextService } from '../../shared/services/site-context.service';
import { ModalAlertService } from './modal-alert.service';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { WechatLogin } from '../auth/login.model';
import { StateStorageService } from '../auth/state-storage.service';
import { HttpErrorHandlerUtil } from '../utilities/http-error-handler-util';
import { TranslateService } from '@ngx-translate/core';
import { take } from "rxjs/operators";
import { AuthenticationMethodEnum } from '../../shared/model/site-security-settings.model';
import { CurrentDeviceService } from './current-device.service';

@Injectable({ providedIn: 'root' })
export class WeChatLoginService implements OnDestroy {
    childWindowForWxAuthorization: Window;
    isWxUa = false;
    sub: Subscription[] = [];
    hasListenerStorage = false;
    actionMessage$: Subject<{ type: string, msg?: string }> = new Subject();
    isPc = true;
    WX_MP_APP_ID: string;
    WX_OPEN_APP_ID: string;
    constructor(
        protected http: HttpClient,
        private toast: ToastService,
        private authService: AuthService,
        private modalAlertService: ModalAlertService,
        private router: Router,
        public siteContextService: SiteContextService,
        public currentDeviceService: CurrentDeviceService,
        private stateStorageService: StateStorageService,
        public httpErrorHandlerUtil: HttpErrorHandlerUtil,
        public translateService: TranslateService,
    ) { }
    openWxAuthorizationWindow(actionType: string = 'loginIn') {
        if (this.isWxUa) {
            if (this.WX_MP_APP_ID) {
                this.authorizationAction(actionType, this.WX_MP_APP_ID);
            } else {
                firstValueFrom(this.authService.getWxMpId()).then(res => {
                    if (res && res.body && res.body.appId) {
                        this.WX_MP_APP_ID = res.body['appId'];
                        this.authorizationAction(actionType, this.WX_MP_APP_ID);
                    } else {
                        this.toast.fail('后台未配置微信公众平台信息，请联系管理员！', 6000);
                    }
                });
            }
        } else {
            if (this.WX_OPEN_APP_ID) {
                this.authorizationAction(actionType, this.WX_OPEN_APP_ID);
            } else {
                firstValueFrom(this.authService.getWxOpenId()).then(res => {
                    if (res && res.body && res.body.appId) {
                        this.WX_OPEN_APP_ID = res.body['appId'];
                        this.authorizationAction(actionType, this.WX_OPEN_APP_ID);
                    } else {
                        this.toast.fail('后台未配置微信公众平台信息，请联系管理员！', 6000);
                    }
                }).catch(err => console.log('err： ', err));
            }
        }
    }
    authorizationAction(actionType: string = 'loginIn', appid: string) {
        const currentUrl = this.router.url;
        const authorizationUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(this.getWechatAuthorizationReturnUrl())}&response_type=code&scope=snsapi_userinfo&state=wechat_phone#wechat_redirect&display=mobile`;
        if (this.isWxUa) {
            window.localStorage.setItem('wxCode_return_url', currentUrl);
            window.localStorage.setItem('wxCode_action_type', actionType);
            window.location.href = authorizationUrl;
        } else {
            if (this.isPc) {
                const qrcodeUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${encodeURIComponent(this.getWechatAuthorizationReturnUrl())}&response_type=code&scope=snsapi_login&state=window#wechat_redirect`;
                // 通过window.open打开个微信扫码登录窗口
                const iTop = (window.screen.height - 30 - 600) / 2;       // 获得窗口的垂直位置;
                const iLeft = (window.screen.width - 10 - 600) / 2;        // 获得窗口的水平位置;
                this.childWindowForWxAuthorization = window.open(qrcodeUrl, '微信登录', 'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes,top=' + iTop + ',left=' + iLeft + ', width=600, height=600');
                this.childWindowForWxAuthorization.focus();
                if (this.hasListenerStorage) { return; }
                window.addEventListener('storage', (event: StorageEvent) => {
                    console.log('接收到：', event.newValue);
                    if (!event.newValue || event.key !== 'wxCode_window') { return; }
                    this.childWindowForWxAuthorization.document.write('<p>微信扫码授权成功！</p>');
                    this.childWindowForWxAuthorization.close();
                    if (actionType === 'loginIn') {
                        this.loginIn(event.newValue);
                    } else {
                        this.wxBind(event.newValue);
                    }
                    localStorage.removeItem('wxCode_window');
                });
                this.hasListenerStorage = true;
            } else {
                const qrcodeUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${encodeURIComponent(this.getWechatAuthorizationReturnUrl())}&response_type=code&scope=snsapi_login&state=wechat_phone#wechat_redirect`;
                window.localStorage.setItem('wxCode_return_url', currentUrl);
                window.localStorage.setItem('wxCode_action_type', actionType);
                window.location.href = qrcodeUrl;
            }
        }
    }

    handleWeChatActionReturn(): string {
        try {
            const wxCode = window?.localStorage?.getItem('wxCode_window');
            const wxCodeActionType = window?.localStorage?.getItem('wxCode_action_type');
            const wxCodeReturnUrl = window?.localStorage?.getItem('wxCode_return_url') || '';
            // 读取后移除以防出现意外的问题
            window?.localStorage?.removeItem('wxCode_action_type');
            window?.localStorage?.removeItem('wxCode_return_url');
            window?.localStorage?.removeItem('wxCode_window');
            // 如果类型或者wxcode不存在则不进行下面操作
            if (!wxCodeActionType || !wxCode) { return null; }
            if (!wxCode || !wxCodeActionType) { return null; }
            if (wxCodeActionType === 'loginIn') {
                this.loginIn(wxCode);
            } else {
                this.wxBind(wxCode);
            }
            return wxCodeReturnUrl;
        } catch (error) {
            console.log('error on handleWeChatActionReturn():', error);
            return '';
        }
    }
    loginIn(code: string) {
        const params = new WechatLogin(code, true);
        const wxLoginSub = this.loginInRequest(params).subscribe(res => {
            console.log('微信登录', res);
            if (res) {
                this.actionMessage$.next({ type: 'loginInSucess' });
                // 授权登录成功，返回原页面
                this.backSourcePageAfterAuthorization();
            } else {
                // 失败则进行弹出模态框进行相应处理
                this.loginFailOfCodeUsed();
            }
        },
            err => {
                console.log('访问失败', err);
                // code已被使用，身份过期
                if (err?.error?.detail?.includes('code been used')) {
                    this.loginFailOfCodeUsed();
                    return;
                    // 没注册
                } else if (err?.error?.message?.includes('wxUserInfo is not exists')) {
                    this.loginFailOfNotRegister();
                    return;
                }
                this.loginFailOfCodeUsed();
            });
        this.sub.push(wxLoginSub);
    }
    wxBind(code: string) {
        const wxBindSub = this.bindWxRequest(code).subscribe(result => {
            this.actionMessage$.next({ type: 'bindWxSucess' });
        }, err => {
            console.log(err);
            if (err.error && err.error.detail && JSON.parse(err.error.detail).errmsg.includes('code been used')) {
                this.toast.fail(`${this.translateService.instant('AUTH.CURRENT_STATUS_HAS_EXPIRED_PLEASE_TRY_AGAIN') || '当前身份已过期，请重新'}${this.isWxUa ? (this.translateService.instant('AUTH.AUTHORIZATION') || '授权') : (this.translateService.instant('AUTH.QRCODE') || '扫码')}……`, 3000);
            } else if (err.error && err.error.detail && JSON.parse(err.error.detail).errmsg.includes('invalid code')) {
                this.toast.fail(`${this.translateService.instant('AUTH.WECHAT_CODE_VERIFICATION_FAILED_PLEASE_TRY_AGAIN') || '微信码校验失败，请重新'}${this.isWxUa ? (this.translateService.instant('AUTH.AUTHORIZATION') || '授权') : (this.translateService.instant('AUTH.QRCODE') || '扫码')}……`, 3000);
            } else {
                this.httpErrorHandlerUtil.errHandle(err)
                    .catch(e => {
                        this.actionMessage$.next({
                            type: 'bindWxFailed',
                            msg: e.msg
                        });
                    });
            }
        });
        this.sub.push(wxBindSub);
    }
    bindWxRequest(code: string) {
        return this.currentDeviceService.isWX() ? this.authService.bindByWxMp(code) : this.authService.bindByWxOpenPlatform(code);
    }
    loginInRequest(params: WechatLogin) {
        return this.currentDeviceService.isWX() ? this.authService.loginByWxMp(params) : this.authService.loginByWxOpenPlatform(params);
    }
    openModal(title: string | TemplateRef<any>, desc: string, successBtnTitle: string, cancelBtnTitle: string): Promise<string> {
        return this.modalAlertService.open(title, desc, {
            successBtnTitle,
            cancelBtnTitle
        });
    }
    loginFailOfNotCode() {
        this.siteContextService.loadSiteSecuritySetting().pipe(take(1)).subscribe(siteSecuritySetting => {
            const canLoginByPhone = siteSecuritySetting?.authenticationMethods?.find(authenticationMethod => authenticationMethod?.authenticationMethod === AuthenticationMethodEnum.MOBILE)?.enableLogin;
            const seconderyBtnTitle = canLoginByPhone ? (this.translateService.instant('AUTH.ACCOUNT_LOGIN') || '账户登录') : (this.translateService.instant('AUTH.RELOAD_TEXT') || '刷新重试');
            this.openModal(this.translateService.instant('GENERAL.TIPS') || '提示',
                canLoginByPhone ? (this.translateService.instant('AUTH.WECHAT_NOT_AUTHORIZATION_FAILED_ERROR_TIPS_FOR_PHONE_LOGIN_ENABLE') || '当前微信未授权，您是否需要 微信授权登录 或 使用账户登录 ?') : (
                    this.translateService.instant('AUTH.WECHAT_NOT_AUTHORIZATION_FAILED_ERROR_TIPS') || '当前微信未授权，您是否需要 微信授权登录 或 刷新重试 ?'
                ),
                this.translateService.instant('AUTH.WECHAT_AUTHORIZATION') || '微信授权',
                seconderyBtnTitle).then(confirm => {
                    this.router.navigate(['/auth/login-by-wxcode']);
                }).catch(err => {
                    console.log('账户登录：', err);
                    this.router.navigate([seconderyBtnTitle === (this.translateService.instant('AUTH.RELOAD_TEXT') || '刷新重试') ? '/auth/default-login' : '/auth/login']);
                }
                );
        });
    }
    loginFailOfNotRegister() {
        this.siteContextService.loadSiteSecuritySetting().pipe(take(1)).subscribe(siteSecuritySetting => {
            const canLoginByPhone = siteSecuritySetting?.authenticationMethods?.find(authenticationMethod => authenticationMethod?.authenticationMethod === AuthenticationMethodEnum.MOBILE)?.enableLogin;
            let content, successBtnTitle, seconderyBtnTitle;
            if (canLoginByPhone) {
                content = this.translateService.instant('AUTH.LOGIN_BY_WECHAT_QRCODE.ERROR_MESSAGE.WECHAT_NOT_REGISTER') || '当前微信暂未注册或绑定手机号，您是否需要前往 微信注册页面 或 使用账户登录 ?';
                successBtnTitle = this.translateService.instant('AUTH.WECHAT_REGISTRATION.TITLE') || '微信注册';
                seconderyBtnTitle = this.translateService.instant('AUTH.ACCOUNT_LOGIN') || '账户登录';
            } else {
                content = this.translateService.instant('AUTH.WECHAT_NOTREGISTERED_ERROR_TIPS') || '当前微信暂未注册，您是否需要前往 微信注册页面 或 刷新重试 ?';
                successBtnTitle = this.translateService.instant('AUTH.WECHAT_REGISTRATION.TITLE') || '微信注册';
                seconderyBtnTitle = this.translateService.instant('AUTH.RELOAD_TEXT') || '刷新重试';
            }
            this.openModal(this.translateService.instant('GENERAL.TIPS') || '提示',
                content,
                successBtnTitle,
                seconderyBtnTitle).then(confirm => {
                    console.log('微信注册');
                    this.router.navigate([this.currentDeviceService.isWX() ? '/auth/get-wxcode-for-register' : '/auth/register-by-wechat']);
                }).catch(err => {
                    console.log('账户登录：', err);
                    this.router.navigate([seconderyBtnTitle === (this.translateService.instant('AUTH.RELOAD_TEXT') || '刷新重试') ? '/auth/default-login' : '/auth/login']);
                }
                );
        });
    }
    loginFailOfCodeUsed() {
        this.openModal(this.translateService.instant('GENERAL.TIPS') || '提示',
            this.translateService.instant('AUTH.CURRENT_WECHAT_AUTHORIZATION_FAILED_TIPS') || '当前微信状态已过期，是否重新授权 ?',
            this.translateService.instant('AUTH.REAUTHORIZE') || '重新授权',
            this.translateService.instant('GENERAL.CANCEL_BTN_TEXT') || '取消').then(confirm => {
                console.log('重新授权');
                this.openWxAuthorizationWindow();
            }).catch(err => {
                console.log('取消：', err);
            });
    }
    // 授权后返回来源页面
    backSourcePageAfterAuthorization() {
        const url = this.stateStorageService.getUrl();
        this.stateStorageService.clearUrl();
        try {
            console.warn('url:', url);
            this.router.navigate([url || '/home'], { replaceUrl: true });
        } catch (error) {
            this.router.navigate(['/home'], { replaceUrl: true });
        }
    }
    getWechatAuthorizationReturnUrl(): string {
        return document?.baseURI.replace('//', '/').replace(':/', '://');
    }
    ngOnDestroy(): void {
        this.actionMessage$.complete();
        this.sub.forEach(item => item.unsubscribe());
    }
}
