import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { SiteContextService } from '../../shared/services/site-context.service';
import { HttpErrorHandlerUtil } from '../utilities/http-error-handler-util';
import { AccountService } from './account.service';

@Injectable({ providedIn: 'root' })
export class Principal {
    private userIdentity: any;
    private authenticated = false;
    private authenticationState = new BehaviorSubject<any>(null);
    constructor(
        private siteContextService: SiteContextService,
        private $localStorage: LocalStorageService,
        private account: AccountService,
        public httpErrorHandlerUtil: HttpErrorHandlerUtil,
        public translateService: TranslateService,
    ) {
        this.identity(true);
    }

    authenticate(identity) {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authenticationState.next(this.userIdentity);
    }

    hasAnyAuthority(authorities: string[]): Promise<boolean> {
        return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            if (this.userIdentity.authorities.includes(authorities[i])) {
                return true;
            }
        }

        return false;
    }

    hasAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then(
            id => {
                return Promise.resolve(id.authorities && id.authorities.includes(authority));
            },
            () => {
                return Promise.resolve(false);
            }
        );
    }

    identity(force?: boolean): Promise<any> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, then resolve.
        return firstValueFrom(this.account
            .get(new HttpHeaders({
                'brics-api-error-notify': 'false',
                'brics-api-success-notify': 'false'
            })))
            .then(response => {
                const account = response?.body;
                if (account) {
                    this.userIdentity = account;
                    this.authenticated = true;
                } else {
                    this.userIdentity = null;
                    this.authenticated = false;
                }
                this.authenticationState.next(this.userIdentity);
                return this.userIdentity;
            })
            .catch(err => {
                const e = this.httpErrorHandlerUtil.transformErrorMessage(err);
                if (e?.msg && e.msg.includes('用户不存在') || e.msg.includes('登录状态已过期') || ['API_ALERT_MESSAGE.ERROR.USERCOULDNOTBEFOUND', 'API_ALERT_MESSAGE.ERROR.UNAUTHORIZED']?.includes(e.instantKeyForLanguage)) {
                    // 用户不存在或者未登录的情况下自动清除token
                    firstValueFrom(this.logout()).then(res => {
                        this.userIdentity = null;
                        this.authenticated = false;
                        this.authenticationState.next(this.userIdentity);
                    }).catch(error => { });
                }
                return null;
            });
    }

    isAuthenticated(): boolean {
        return this.authenticated;
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    getImageUrl(): string {
        return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
    }
    authenticateSuccess(resp, rememberMe) {
        if (resp.openId) {
            this.$localStorage.store('wxOpenId', resp.openId);
        }
        if (resp.headers) {
            const bearerToken = resp.headers.get('Authorization');
            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                const jwt = bearerToken.slice(7, bearerToken.length);
                // console.log('保存token', jwt);
                this.storeAuthenticationToken(jwt, rememberMe || true);
                this.identity(true);
            }
        } else if (resp.id_token) {
            const jwt = resp.id_token;
            // console.log('保存token', jwt);
            this.storeAuthenticationToken(jwt, rememberMe || true);
            this.identity(true);
        }
        return resp;
    }
    storeAuthenticationToken(jwt, rememberMe) {
        this.siteContextService.setToken(jwt, rememberMe);
        this.userInfo();
    }
    userInfo(): void {
        // 这个api需要token访问
        this.account.getCurrentCustomer(new HttpHeaders({
            'brics-api-error-notify': 'false',
            'brics-api-success-notify': 'false'
        }))
            .subscribe(
                (res) => { },
                error => {
                    console.log('访问失败', error);
                    // this.$localStorage.clear();
                });
    }
    logout(): Observable<any> {
        return new Observable(observer => {
            this.siteContextService.loginOut();
            this.authenticate(null);
            observer.next();
        });
    }
}
