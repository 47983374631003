import {
    Component,
    OnInit,
    ChangeDetectorRef,
    EventEmitter,
    Output,
    Input,
    OnChanges,
    SimpleChanges,
    afterNextRender,
} from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { changeSiteMeta } from '../../../assets/js/tools/utils';
import { TranslationService } from '../../../i18n';
import { Layout, defaultSiteLayout } from '../../shared/model/layouts.model';
import { Menu } from '../../shared/model/menu.model';
import { NavigationLayout } from '../../shared/model/navigation-layout.enum';
import { Page } from '../../shared/model/page.model';
import * as pageConfig from '../../shared/model/page.model';
import { Site } from '../../shared/model/site.model';
import { HttpErrorHandlerUtil } from '../../core/utilities/http-error-handler-util';
import { CurrentDeviceService } from '../../core/services/current-device.service';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { TitleTranslatePipe } from '../../shared/pipes/title-translate.pipe';
import { LayoutService } from '../../shared/services/layouts.service';
import { PageService, normalizePageBlocks } from '../../shared/services/page.service';
import { SiteContextService } from '../../shared/services/site-context.service';
import { SiteMenusService } from '../../shared/services/site-menus.service';
import { debounceTime, take } from 'rxjs/operators';
import { findPageMenu, findPageMenuById } from '../../core/utilities/page-route-util';
import { firstValueFrom, fromEvent, Subscription } from 'rxjs';
// declare const window: any;

export enum QueryType {
    preSaleProduct = 'preSaleProduct',
    labelProduct = 'labelProduct',
    extendProduct = 'extendProduct',
    newProduct = 'newProduct',
    hotProduct = 'hotProduct'
}
@Component({
    selector: 'app-page-block-renderer',
    templateUrl: './page-block-renderer.component.html',
    styleUrls: ['./page-block-renderer.component.scss'],
    providers: [TitleTranslatePipe]
})
export class PageBlocksRendererComponent implements OnInit, OnChanges {
    @Input() initializationPage: Page = null;
    @Input() enableLoading = true;
    @Input() pageName = '';
    @Input() renderHeaderBlocks = true;
    @Input() pageContainerClass = '';
    @Input() contentContainerClass = '';
    @Input() pageId: string;
    @Input() autoSetMeta: boolean = true;
    @Output() loadPageBlocksEvent = new EventEmitter();
    isMobile;
    queryType = QueryType;
    page = null;
    siteMenus: Menu = null;
    isLoading = true;
    loadedBasicBlockData: boolean;
    layout = new Layout();
    site = new Site();
    NavigationLayout = NavigationLayout;
    layoutRenderOptions: any = {
        desktopNavigation: NavigationLayout.TOP_BAR,
        mobileNavigation: NavigationLayout.BOTTOM_TAB_BAR
    };
    isLoadingPage: boolean = false;
    nothingText: string;
    loadSiteError: boolean;
    subscriptions: Subscription[] = [];

    constructor(
        public currentDeviceService: CurrentDeviceService,
        public httpErrorHandlerUtil: HttpErrorHandlerUtil,
        public translateService: TranslateService,
        public pageService: PageService,
        public translationService: TranslationService,
        public siteContextService: SiteContextService,
        public $localStorage: LocalStorageService,
        public cdr: ChangeDetectorRef,
        public siteMenusService: SiteMenusService,
        public route: ActivatedRoute,
        public router: Router,
        private layoutService: LayoutService,
        private titleTranslatePipe: TitleTranslatePipe,
    ) {
        afterNextRender(() => {
            // 开启 监听设备宽度变化，200ms内防抖处理，实时检测当前设备宽度
            let sub = fromEvent(window, 'resize').pipe(debounceTime(200))
            .subscribe(() => {
                this.isMobile = this.currentDeviceService.isMobile();
                this.cdr.markForCheck();
            });
            this.subscriptions.push(sub);
        });
     }

    ngOnInit() {
        this.isMobile = this.currentDeviceService.isMobile();
        let siteGraph = this.siteContextService.siteGraph;
        this.site = siteGraph?.site;
        this.loadSiteError = siteGraph?.errorMock;
        this.renderLayout(siteGraph?.layouts?.[0]);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['initializationPage']?.previousValue !== changes['initializationPage']?.currentValue) {
            const page = changes['initializationPage']?.currentValue;
            if (page) {
                this.isLoadingPage = false;
                this.isLoading = false;
                this.initPage(page, true);
            }
        }
        if (changes['pageName']?.previousValue !== changes['pageName']?.currentValue) {
            if (this.pageName) {
                if (!this.isLoadingPage) {
                    const siteId = this.siteContextService.siteId;
                    this.getPageByName(siteId, { 'name.equals': this.pageName });
                }
            }
        }
        if (changes['pageId']?.previousValue !== changes['pageId']?.currentValue) {
            if (this.pageId) {
                if (!this.isLoadingPage) {
                    this.findAndInitPageById(this.pageId);
                }
            }
        }
    }

    getPageByName(siteId: string, req: any = { 'name.contains': 'Home' }) {
        this.isLoadingPage = true;
        firstValueFrom(this.pageService.query({
            'siteId.equals': siteId,
            ...req,
        })).then(pagesRes => {
            if (this.loadSiteError) {
                return;
            }
            if (pagesRes.body.length) {
                let matchedPageId = pagesRes.body[0]?.id;
                if (matchedPageId) {
                    this.isLoadingPage = false;
                    this.isLoading = false;
                    this.initPage(pagesRes.body[0]);
                }
            }
        }).catch(err => {
            this.nothingText = this.translationService.instant('SITES.ERROR.LOAD_SITE_ERROR');
            this.isLoading = false;
            this.loadPageBlocksEvent.emit(false);
            this.cdr.detectChanges();
            this.cdr.markForCheck();
        });
    }

    findAndInitPageById(pageId: string) {
        this.isLoadingPage = true;
        firstValueFrom(this.pageService.find(pageId)).then(pageRes => {
            this.isLoadingPage = false;
            this.isLoading = false;
            this.initPage(pageRes.body);
        }).catch(err => {
            this.nothingText = this.translationService.instant('SITES.ERROR.LOAD_SITE_ERROR');
            this.isLoading = false;
            this.loadPageBlocksEvent.emit(false);
            this.cdr.detectChanges();
            this.cdr.markForCheck();
        });
    }

    textTransformCapitalize(rawStr: string) {
        return rawStr?.toLocaleLowerCase()?.split('_')?.map(str => str?.toLowerCase()?.replace(/( |^)[a-z]/g, (L) => L?.toUpperCase()))?.join('');
    }

    getDefaultPage(pageName: string) {
        return new Page((pageConfig?.[`default${this.textTransformCapitalize(pageName)}Page`]) as unknown as Page);
    }

    initPage(page, emitLoadedEvent = true) {
        if (!page?.blocks?.length && this.getDefaultPage(page.name)) {
            page.blocks = this.getDefaultPage(page.name)?.blocks;
        }
        this.loadLayout(page?.layoutId);
        if (!page.builtIn && !page?.blocks?.length) {
            this.nothingText = this.translationService.instant('SITES.ERROR.LOAD_PAGE_EMPTY');
        }
        normalizePageBlocks(page);
        // 如果不一致的话则设置，反之不进行重新渲染
        if (JSON.stringify(this.page) !== JSON.stringify(page)) {
            this.page = page;
            if (this.autoSetMeta) {
                const siteMenu = this.siteContextService.defaultSiteMenu;
                this.route.paramMap.subscribe(paramMap => {
                    const currentPageMenu = findPageMenuById(siteMenu?.items, paramMap?.get('path')) || findPageMenu(siteMenu?.items, this.router.url);;
                    changeSiteMeta(this.titleTranslatePipe.transform(currentPageMenu || page));
                    this.translateService.onLangChange.subscribe(e => {
                        changeSiteMeta(this.titleTranslatePipe.transform(currentPageMenu || page));
                    });
                });
            }
        }
        this.isLoading = false;
        if (emitLoadedEvent) {
            this.loadPageBlocksEvent.emit(this.page);
        }
        this.cdr.detectChanges();
    }

    renderLayout(layout: Layout) {
        if (!layout || !layout?.blocks?.length) {
            layout = new Layout({
                ...layout,
                blocks: defaultSiteLayout?.blocks,
            } as unknown as Layout);
        }
        layout = normalizePageBlocks(layout);
        this.layoutRenderOptions = jsonParse(layout?.renderOptions) || {
            desktopNavigation: NavigationLayout.TOP_BAR,
            mobileNavigation: NavigationLayout.BOTTOM_TAB_BAR
        };
        // 如果不一致的话则设置，反之不进行重新渲染
        if (JSON.stringify(this.layout) !== JSON.stringify(layout)) {
            this.layout = layout;
        }
        this.cdr.detectChanges();
        this.cdr.markForCheck();
    }

    loadLayout(layoutId: string) {
        let layout: Layout;
        if (!['DEFAULT', '', undefined, null]?.includes(layoutId)) {
            firstValueFrom(this.layoutService.find(layoutId)).then(res => {
                if (res?.body?.blocks?.length) {
                    layout = res?.body;
                }
            }).catch(err => { });
        } else {
            layout = defaultSiteLayout as unknown as Layout;
        }
        this.renderLayout(layout);
    }

    checkBannerEnableForCurrentPage(pageBlock) {
        if (pageBlock?.data?.homePageOnly === true) {
            return ['HOME', 'home'].includes(this.pageName?.toLowerCase());
        }
        return true;
    }

    checkHeaderBackgroundEnableForCurrentPage(pageBlock) {
        if (pageBlock?.renderOptions?.backgroundImgHomePageOnly === true) {
            return ['HOME', 'home'].includes(this.pageName?.toLowerCase());
        }
        return true;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
