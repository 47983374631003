import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '../../../../i18n';
import { CurrentDeviceService } from '../../../core/services/current-device.service';
import { QueryType } from '../../../core/model/query-type.enum';
import { HttpErrorHandlerUtil } from '../../../core/utilities/http-error-handler-util';
import { jsonParse, NameTranslatePipe } from '../../../shared/pipes/name-translate.pipe';
import { SiteContextService } from '../../../shared/services/site-context.service';
import { GroupBuyingCampaignsService } from '../../shared/service/group-buying-campaigns.service';
import { ProductService } from '../../shared/service/product.service';
import { ShopContextService } from '../../shared/service/shop-context.service';
import groupBuyingTranform from '../../shared/utils/group-buying-tranform';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-label-products-block',
    templateUrl: './label-products.component.html',
    styleUrls: ['./label-products.component.scss'],
    providers: [NameTranslatePipe]
})
export class LabelProductsBlockComponent implements OnInit {
    @Input() title: string = '';
    @Input() salesVisible = true;
    @Input() titleTranslation = '';
    @Input() blockId: string = '';
    @Input() moreProductsQueryParams = {
        queryType: QueryType.labelProduct,
    }
    @Input() set data(value: string) {
        if (value) {
            this.productLabels = jsonParse(value)?.labels;
            this.selectedLabelIndex = 0;
            this.labelChecked(this.selectedLabelIndex, this.productLabels[this.selectedLabelIndex]);
        } else {
            this.showLoading = false;
            this.showProductsMore = false;
            this.products = [];
            this.cdr.markForCheck();
        }
    }
    @Input() renderOptions: any = {};
    @Input() section: string;
    productName: string = '商品';
    productLabels = [];
    products = [];
    showLoading = true;
    loadFailed = false;
    loadFailedMsg = '';
    selectedLabelIndex = 0;
    showProductsMore: boolean = false;
    constructor(
        private productService: ProductService,
        public currentDeviceService: CurrentDeviceService,
        public httpErrorHandlerUtil: HttpErrorHandlerUtil,
        private route: Router,
        private shopContextService: ShopContextService,
        private nameTranslatePipe: NameTranslatePipe,
        private cdr: ChangeDetectorRef,
        private siteContextService: SiteContextService,
        private translationService: TranslationService,
        private groupBuyingCampaignsService: GroupBuyingCampaignsService,
    ) { }

    changeLoadFailedMsgByTranslate() {
        this.loadFailedMsg = this.translationService.instant('GENERAL.LOAD_LABEL_PRODUCT_FAILED', { productName: this.productName },
            '相关' + this.translationService.instant('GENERAL.PRODUCT_DEFAULT_NAME', {}, '商品') + '找不到或已删除');
    }

    ngOnInit(): void {
        const shopId = this.shopContextService.shopId;
        if (!shopId) {
            this.showLoading = false;
            this.loadFailed = true;
            this.changeLoadFailedMsgByTranslate();
            this.cdr.markForCheck();
        }
        const shopSetting = this.shopContextService.shopSetting;
        this.productName = this.nameTranslatePipe.transform(shopSetting?.mobileAppConfig?.general, 'productName');
        if (this.loadFailedMsg) {
            this.changeLoadFailedMsgByTranslate();
        }
    }

    labelChecked(index: number, label: string) {
        this.selectedLabelIndex = index;
        this.getLabelProducts(label);
    }

    getLabelProducts(label: string) {
        if (label) {
            this.loadFailed = false;
            this.loadFailedMsg = '';
            this.showLoading = true;
            this.productService.queryAndStatistics({
                size: this.currentDeviceService.isDesktop() ? (this.renderOptions?.fullWidth ? 7 : 5) : 4,
                'onShelf.equals': true,
                sort: 'createdDate,desc',
                'hiddenInList.equals': false,
                'label.contains': label,
                'shopId.equals': this.shopContextService.shopId,
            }).subscribe(
                (res: HttpResponse<any[]>) => {
                    this.showLoading = false;
                    const total = parseInt(res.headers.get('x-total-count'), 10);
                    this.showProductsMore = Boolean(total > (this.currentDeviceService.isDesktop() ? (this.renderOptions?.fullWidth ? 7 : 5) : 4));
                    this.products = res.body.map((item: any) => {
                        item['img'] = item.imgUrl;
                        return item;
                    });
                    if (this.products?.length) {
                        firstValueFrom(this.groupBuyingCampaignsService.query({ 'productId.in': this.products?.map(product => product?.id) })).then(result => {
                            this.products?.forEach(product => {
                                const findGroupBuyingCampaign = result.body?.find(groupBuyingCampaign => groupBuyingCampaign?.id === product?.id);
                                const groupBuyingCampaigns = groupBuyingTranform.initGroupBuyingCampaigns(findGroupBuyingCampaign, null);
                                product.requiredMembers = groupBuyingCampaigns.requiredMembers;
                                product.allowOnlyNewCustomer = groupBuyingCampaigns.allowOnlyNewCustomer;
                            });
                        }).catch(() => { });
                    }
                    this.cdr.markForCheck();
                }, error => {
                    this.showLoading = false;
                    this.loadFailed = true;
                    this.changeLoadFailedMsgByTranslate();
                    this.cdr.markForCheck();
                }
            );
        } else {
            this.showLoading = false;
        }
    }
    reloadProducts() {
        this.getLabelProducts(this.productLabels[this.selectedLabelIndex]);
    }
    navigateMoreProducts() {
        this.route.navigate(['/more-products'], {
            queryParams: {
                name: this.productLabels[this.selectedLabelIndex],
                label: (this.selectedLabelIndex && this.productLabels?.length) ? this.productLabels[this.selectedLabelIndex] : '',
                ...this.moreProductsQueryParams,
                productImageSize: (this.renderOptions?.productImageSize === 'BIG') ? 'BIG' : 'SMALL',
                originalPriceVisible: this.renderOptions?.originalPriceVisible,
                taxIncludedVisible: this.renderOptions?.taxIncludedVisible,
                enableDesktopMarginRightAuto: this.renderOptions?.fullWidth,
                enableAddShoppingCartBtn: this.renderOptions?.enableShoppingCartBtn,
                salesVisible: this.salesVisible,
            }
        });
    }
}
