import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PostConfig } from '../../builtin-pages/post-list/_model/post.model';
import { PageBlockTypeEnum } from '../../shared/model/page-block-type.enum';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { PageService } from '../../shared/services/page.service';
import { TargetEntityTypeEnum } from '../model/mobile-config.model';
import { QueryType } from '../model/query-type.enum';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ParseTargetEntityConfigService {
    constructor(
        public pageService: PageService,
        public router: Router,
    ) { }

    navigateLinkedPage(entityConfig: any) {
        switch (entityConfig?.targetType) {
            case 'URL':
                if (entityConfig?.targetUrl) { window.open(entityConfig?.targetUrl); }
                break;
            case 'NONE':
                break;
            case 'ENTITY':
                const entityType = entityConfig?.targetEntityType;
                if (entityType === 'PRODUCT') {
                    this.router.navigateByUrl('/product?id=' + entityConfig?.targetEntityId);
                } else if (entityType === 'PRODUCT_CATEGORY') {
                    this.router.navigateByUrl('/more-products?categoryId=' + entityConfig?.targetEntityId
                        + '&name=' + (entityConfig?.title || entityConfig?.caption));
                } else if (entityType === 'PRODUCT_TAG') {
                    this.router.navigateByUrl('/more-products?queryType=' + QueryType.labelProduct
                        + '&label=' + entityConfig?.targetUrl + '&name=' + (entityConfig?.targetUrl || entityConfig?.caption));
                } else if (entityType === TargetEntityTypeEnum.POST_LIST) {
                    const params: any = entityConfig.postConfig || new PostConfig();
                    this.router.navigate(['/post/list'], {
                        queryParams: params
                    });
                } else if (entityType === TargetEntityTypeEnum.POST_CATEGORY) {
                    const params: any = entityConfig.postConfig || new PostConfig();
                    params.categoryId = entityConfig.targetEntityId;
                    this.router.navigate(['/post/list'], {
                        queryParams: params
                    });
                } else if (entityType === TargetEntityTypeEnum.POST) {
                    const params: any = entityConfig.postConfig || new PostConfig();
                    this.router.navigate(['/post', entityConfig.targetEntityId], {
                        queryParams: params
                    });
                } else if (entityType === TargetEntityTypeEnum.PAGE) {
                    this.navigateByPageId(entityConfig.targetEntityId);
                } else if (entityType === TargetEntityTypeEnum.FORM) {
                    this.router.navigate(['/forms', entityConfig.targetEntityId]);
                }
                break;
            default:
                if (entityConfig?.targetUrl) { window.open(entityConfig?.targetUrl); }
                break;
        }
    }

    navigateByPageId(pageId: string) {
        firstValueFrom(this.pageService.find(pageId)).then(page => {
            const blocks = page.body?.blocks;
            if (page?.body?.slug) {
                this.router.navigate([page?.body?.slug], {
                    replaceUrl: true,
                });
            }
            let flag = false;
            blocks?.forEach(block => {
                if (block?.enabled) {
                    switch (block?.blockType) {
                        case PageBlockTypeEnum.PAGED_CATEGORY_PRODUCT:
                            flag = true;
                            this.router.navigate([`/page/${pageId}/product-category`], {
                                replaceUrl: true,
                                queryParams: {
                                    categoryId: jsonParse(block?.data).targetEntityId
                                }
                            });
                            return;
                        case PageBlockTypeEnum.PAGED_FORMS:
                            flag = true;
                            this.router.navigate([`/page/${pageId}/forms/${jsonParse(block?.data).targetEntityId}`], {
                                replaceUrl: true,
                            });
                            return;
                        case PageBlockTypeEnum.PAGED_POSTS:
                            flag = true;
                            this.router.navigate([`/page/${pageId}/post/${jsonParse(block?.data).targetEntityId}`], {
                                replaceUrl: true,
                            });
                            return;
                        case PageBlockTypeEnum.PAGED_PRODUCTS:
                            flag = true;
                            this.router.navigate([`/page/${pageId}/product`], {
                                replaceUrl: true,
                                queryParams: {
                                    id: jsonParse(block?.data).targetEntityId
                                }
                            });
                            return;
                        case PageBlockTypeEnum.PAGED_PRODUCT_CATEGORY:
                            flag = true;
                            this.router.navigate([`/page/${pageId}/product-category`], {
                                replaceUrl: true,
                                queryParams: {
                                    categoryId: jsonParse(block?.data).targetEntityId
                                }
                            });
                            return;
                        case PageBlockTypeEnum.PAGED_POST_LIST:
                            flag = true;
                            this.router.navigate([`/page/${pageId}/post/list`], {
                                replaceUrl: true,
                                queryParams: jsonParse(block?.data)
                            });
                            return;
                        default:
                            break;
                    }
                }
            });
            if (!flag) {
                this.router.navigate([`/page/${pageId}`], {
                    replaceUrl: true,
                });
            }
        }).catch(err => {
            this.router.navigate([`/404`], {
                replaceUrl: true,
            });
        });
    }
}
